import { FC, useEffect } from 'react';
import { useSearchParams } from 'react-router';
import { api } from 'src/lib/api';
import { ApiError } from 'src/lib/errors';
import { log } from 'src/lib/log';

export const AccessTokenRefreshPage: FC = () => {
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const refresh = async () => {
      const return_to = searchParams.get('return_to') || '/';
      try {
        // Refresh or delete refresh token and access token in browser cookie.
        log('refresh_access_token', {
          action: 'refresh',
          return_to,
        });
        await api.dispatch('POST', '/v1/auth/access_token');
      } catch (error: unknown) {
        if (error instanceof ApiError) {
          log('refresh_access_token', {
            action: 'error',
            error: error.code,
          });
          // Ignore missing_refresh_token or invalid_or_expired_token error.
          switch (error.code) {
            case 'missing_refresh_token':
            case 'invalid_or_expired_token':
              break;
            default:
              throw error;
          }
        } else {
          throw error;
        }
      }
      log('refresh_access_token', {
        action: 'redirect',
        return_to,
      });
      if (return_to.startsWith('/')) {
        window.location.replace(return_to);
      } else {
        window.location.replace('/');
      }
    };
    refresh();
  }, [searchParams]);
  return <></>;
};
