import { FC, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { onRegisterAccount } from 'src/actions/account';
import { CheckField } from 'src/components/control/CheckField';
import { Form } from 'src/components/control/Form';
import { FormButton } from 'src/components/control/FormButton';
import { InputField } from 'src/components/control/InputField';
import { PasswordField } from 'src/components/control/PasswordField';
import { ContinueWithGoogleButton } from 'src/components/features/auth/ContinueWithGoogleButton';
import { Button, LinkButton } from 'src/components/primitives/Button';
import { HorizontalDivider } from 'src/components/primitives/HorizontalDivider';
import { useI18n } from 'src/lib/i18n';
import { buildAccountDisplayNameOptions, RegisterAccountBody } from 'src/models/v1/account';

export const RegisterForm: FC<{ debuggable?: boolean }> = ({ debuggable = import.meta.env.DEV }) => {
  const { i18n } = useI18n();
  return (
    <>
      <Form onSubmit={onRegisterAccount({})} mode="onChange" defaultRequired>
        <InputField
          name="account.display_name"
          autoFocus
          autoComplete="name"
          options={{ required: true, ...buildAccountDisplayNameOptions() }}
        />
        <InputField name="account.email" type="email" autoComplete="email" options={{ required: true }} />
        <PasswordField
          name="account.password"
          options={{
            required: true,
          }}
        />
        <CheckField name="confirmation" options={{ required: true }}>
          <AgreementGuide />
        </CheckField>
        <FormButton>{i18n.t('action.create_account')}</FormButton>
        {debuggable && <RegisterFormDebugger />}
      </Form>
      <ContinueWithGoogleButton />
      <HorizontalDivider>{i18n.t('guide.have_an_account_already')}</HorizontalDivider>
      <LinkButton to="/login" variant="outline" size="small">
        {i18n.t('action.login')}
      </LinkButton>
    </>
  );
};

const AgreementGuide: FC = () => {
  const { i18n, locale } = useI18n();
  switch (locale) {
    case 'ja':
      return (
        <p>
          <a className="link" href="/terms_of_service" target="_blank" rel="noopener noreferrer">
            {i18n.t('title.terms_of_service')}
          </a>
          ・
          <a className="link" href="/privacy_policy" target="_blank" rel="noopener noreferrer">
            {i18n.t('title.privacy_policy')}
          </a>
          に同意する
        </p>
      );
    default:
      return (
        <p>
          I accept{' '}
          <a className="link" href="/terms_of_service" target="_blank" rel="noopener noreferrer">
            {i18n.t('title.terms_of_service')}
          </a>
          {' and '}
          <a className="link" href="/privacy_policy" target="_blank" rel="noopener noreferrer">
            {i18n.t('title.privacy_policy')}
          </a>
        </p>
      );
  }
};

const RegisterFormDebugger: FC = () => {
  const { setValue } = useFormContext<RegisterAccountBody & { confirmation: boolean }>();
  const onFillTestAccount = useCallback(() => {
    const key = Math.floor(Math.random() * 10000);
    setValue('account.display_name', `Test Account ${key}`);
    setValue('account.email', `test-account-${key}-${Math.floor(Math.random() * 10000)}@example.test`);
    setValue('account.password', 'password1234!!');
    setValue('confirmation', true, { shouldValidate: true });
  }, [setValue]);
  return <Button onClick={onFillTestAccount}>Fill Test Account</Button>;
};
