import { PolyglotOptions } from 'node-polyglot';

const PERSON_NAME_ATTRIBUTES = {
  last_name: '姓',
  last_name_kana: '姓(カナ)',
  first_name: '名',
  first_name_kana: '名(カナ)',
};

const ADDRESS_ATTRIBUTES = {
  postal_code: '郵便番号',
  address_level: '都道府県・市区町村',
  state: '都道府県',
  city: '市区町村',
  city_kana: '市区町村 (カナ)',
  town: '番地',
  town_kana: '番地 (カナ)',
  line1: '丁目・番地・号',
  line1_kana: '丁目・番地・号 (カナ)',
  line2: '建物名・号室',
  line2_kana: '建物名・号室(カナ)',
};

const phrases = {
  nagaku: 'ナガク',

  /**
   * MARK: Title
   * For <title> tag and the lergest heading within each page.
   */
  'title.home': 'プロや事例を探して依頼できるリペア・リメイクサービス',
  'title.login_to_nagaku': 'ログイン',
  'title.signup_to_nagaku': '新規登録',
  'title.feed': 'フィード',
  'title.tags': 'タグ一覧',
  'title.explore': 'タグから探す',
  'title.contact': 'お問い合わせ',
  'title.new_work': '新規投稿',
  'title.edit_work': '投稿の編集',
  'title.draft_work': '下書き',
  'title.likes': 'いいね ! した投稿',
  'title.work': '{{display_name}}さんの投稿',
  'title.messages': 'メッセージ',
  'title.reset_password': 'パスワードの再設定',
  'title.latest_announcement': 'お知らせ',
  'title.setting': '設定',
  'title.follows': 'フォロー中',
  'title.followers': 'フォロワー',
  'title.account_setting': 'アカウント情報',
  'title.addresses_setting': '配送先住所の管理',
  'title.business_application_information': 'プロアカウント申請情報',
  'title.business_profile': 'ビジネス情報',
  'title.reception': '相談ページの編集',
  'title.email_verification': 'メールアドレスの確認',
  'title.jp_act_on_specified_commercial_transaction': '特定商取引法に基づく表記',
  'title.jp_act_on_specified_commercial_transaction_edit': '特定商取引法に基づく表記',
  'title.pro_account_application': 'プロアカウント申請',
  'title.privacy_policy': 'プライバシー​ポリシー',
  'title.terms_of_service': '利用規約',
  'title.announcements': 'お知らせ',
  'title.order': '依頼・受注',
  'title.order_history': '依頼履歴',
  'title.tag': '#{{typeface}} - リペア（修理）、リメイクのおすすめプロ情報、事例、依頼の相談など',
  'title.profile_edit_page': 'プロフィールの編集',
  'title.japanese_act_on_specified_transactions': '特定商取引法に基づく表記',
  'title.draft_works': '下書き',
  'title.inquiry': 'お問い合わせ',
  'title.quotation': '見積書兼請求書',
  'title.quotation_payment': '依頼の確認・決済',
  'title.dashboard': {
    orders: '受注一覧',
  },
  'title.orders': '依頼一覧',
  // for Pro Account Consultation
  'title.ask': '依頼の相談',

  /**
   * MARK: Description
   * For <meta name="description"> tag and similar tags.
   */
  'description.prefix': '【ウェブで相談・見積もり】',
  'description.short':
    'プロや事例を探して依頼できるリペア・リメイクサービス。全国の金継ぎ、ジュエリー、着物、家具、包丁研ぎ、スニーカー、革製品などのプロが参加しています。メッセージでかんたんに相談できて、見積もりから決済、依頼完了までオンラインで行えます。',
  'description.explore':
    'タグでプロや事例を検索。リペア・リメイクがメッセージでかんたんに相談できて、見積もりから決済、依頼完了までオンラインで。金継ぎ、ジュエリー、着物、家具、包丁研ぎ、スニーカー、革製品など。',
  'description.tags':
    'ナガクに登録されているタグの一覧。リペア・リメイクがメッセージでかんたんに相談できて、見積もりから決済、依頼完了までオンラインで。金継ぎ、ジュエリー、着物、家具、包丁研ぎ、スニーカー、革製品など。',
  'description.tag':
    '{{typeface}}のリペア・リメイクのプロや事例が多数掲載。メッセージでかんたんに相談できて、見積もりから決済、依頼完了までオンラインで行えます。',
  'description.own_ask_page': 'これはあなたの相談ページです。',
  'description.ask_page_not_accept_repair_request': '現在、新規依頼の受付を停止しています。',
  /**
   * MARK: Label
   * Simple nouns? Requires refactoring.
   */
  'label.about_consultation_and_requests': '相談・依頼について',
  'label.accounts': 'ユーザー',
  'label.business': 'ビジネス',
  'label.cancellation_policy': 'キャンセルポリシー',
  'label.tags': 'タグ一覧',
  'label.caller': '依頼者',
  'label.estimate': '概算見積もり',
  'label.draft_estimate': '概算見積もりの下書き',
  'label.display_name': 'ユーザー名',
  'label.email': 'メールアドレス',
  'label.general_ticket_steps': '一般的な依頼の流れ',
  'label.privacy_policy': 'プライバシーポリシー',
  'label.japanese_act_on_specified_transactions': '特定商取引法に基づく表記',
  'label.help_and_contact_us': 'ヘルプ・お問い合せ',
  'label.like': 'いいね',
  'label.menu': 'メニュー',
  'label.menu.home': 'ホーム',
  'label.menu.messages': 'メッセージ',
  'label.menu.search_by_tag': 'タグから探す',
  'label.menu.likes': 'いいね ! した投稿',
  'label.menu.profile': 'プロフィール',
  'label.menu.recommend_login': 'ナガクにログインして、リペア・リメイクのプロを見つけましょう。',
  'label.menu.settings': '設定',
  'label.menu.dashboard.orders': '受注一覧',
  'label.menu.order_history': '依頼履歴',
  'label.or': 'または',
  'label.other': 'その他',
  'label.page_hierarchy': 'ページの階層',
  'label.pagination': 'ページネーション',
  'label.password': 'パスワード',
  'label.please_login': 'ログインしてください',
  'label.please_verify_email': 'メールアドレスを確認してください',
  'label.primary_address': '主な住所',
  'label.pro': 'プロ',
  'label.pro_accounts': 'プロアカウント',
  'label.notes_on_requests_and_consultations': '依頼、相談に関する注意事項',
  'label.about_order': '依頼について',
  'label.quotation': '確定見積もり（請求）',
  'label.draft_quotation': '見積もりの下書き',
  'label.quotation.caller': '依頼者',
  'label.quotation.repairer': '受注者',
  'label.quotation.note': '備考',
  'label.quotation.overview': '修理の概要',
  'label.quotation.properties': '文書情報',
  'label.quotation.items': '決済内容',
  'label.quotation.accounts': '依頼者・受注者',
  'label.quotation.billing_address': '請求先住所',
  'label.quotation.jp_trn_number': '適格事業者番号',
  'label.repairer': '受注者',
  'label.remove': '削除',
  'label.setting_items': {
    account_setting: 'アカウント情報',
    addresses_setting: '配送先住所の管理',
    reception: `相談ページの編集`,
    business_application: 'プロアカウント申請',
    business_profile: 'ビジネス情報',
    jp_act_on_spacified_commercial_transaction: '特定商取引法に基づく表記',
    business_application_information: 'プロアカウント申請情報',
    profile_edit_page: 'プロフィールの編集',
  },
  'label.jp_act_on_specified_commercial_transaction': {
    hidden_address: '住所',
    hidden_phone: '電話番号',
  },
  'label.business_profile.address': '住所',
  'label.status': 'ステータス',
  'label.ticket_participants': '参加者',
  'label.ticket_steps': {
    consultation: '相談',
    quotation: '見積もり',
    payment: '代金の支払い',
    processing: '作業の実施',
    delivery: '納品・依頼の完了',
  },
  'label.unverified_email': '未確認のアドレス',
  'label.upload_image': '画像をアップロード',
  'label.verified': '確認済み',
  'label.verification_document': {
    individual_number_card: 'マイナンバーカード (顔写真付き)',
    drivers_license: '運転免許証',
    residence_card: '在留カード・特別永住者証明書',
    japanese_passport: '日本旅券',
    residence_certificate: '住民票',
  },
  'label.verification_document_side': {
    front: '表面',
    back: '裏面',
  },
  'label.verification_document_status': {
    uploaded: 'アップロード済み',
    pending: '要アップロード',
  },
  'label.work': '投稿',
  'label.works': '投稿',
  'label.see_information': '情報を見る',

  'alt.photo_by': '撮影: {{display_name}}',

  // Requires replacing.
  name: {
    individual_number_card: 'マイナンバーカード',
    individual_number_card_front: 'マイナンバーカード (表)',
    individual_number_card_back: 'マイナンバーカード (裏)',
    drivers_license: '運転免許証',
    drivers_license_front: '運転免許証 (表)',
    drivers_license_back: '運転免許証 (裏)',
  },

  /**
   * MARK: Attribute
   * Names of attributes within each model.
   * These phrases are used in any Field components.
   */
  attributes: {
    work: {
      before_image_media_file: 'ビフォア画像',
      after_image_media_file: 'アフター画像',
      image_media_files: 'その他画像',
      body: '投稿文',
      tags: 'タグ',
      estimate_amount: '料金',
      estimate_delivery_days: '制作期間',
    },
    account: {
      display_name: '名前',
      screen_name: 'アカウントID',
      tagline: 'タグライン（提供サービスの簡単な説明）',
      email: 'メールアドレス',
      new_email: '新しいメールアドレス',
      current_email: '現在のメールアドレス',
      bio: 'プロフィール',
      current_password: '現在のパスワード',
      password: 'パスワード',
      new_password: '新しいパスワード',
      confirmation_password: '新しいパスワード(確認用)',
    },
    address: {
      name: 'お名前',
      name_kana: 'お名前 (カナ)',
      phone: '電話番号',
      address: '住所',
      ...ADDRESS_ATTRIBUTES,
    },
    business: {
      business_type: '事業種別',
      jp_trn_number: 'インボイス登録番号',
      verification_document_type: '証明書の種類',
      profile: {
        description: '事業の説明',
      },
      company: {
        name: '法人名(ローマ字または英字)',
        name_kanji: '法人名(漢字)',
        name_kana: '法人名(カナ)',
        phone: '電話番号',
        tax_id: '法人番号',
        email: 'メールアドレス',
        ...ADDRESS_ATTRIBUTES,
      },
      individual: {
        dob: '生年月日',
        phone: '電話番号',
        email: 'メールアドレス',
        ...PERSON_NAME_ATTRIBUTES,
        ...ADDRESS_ATTRIBUTES,
      },
      representative: {
        dob: '生年月日',
        title: '肩書/役職',
        phone: '電話番号',
        email: 'メールアドレス',
        ...PERSON_NAME_ATTRIBUTES,
        ...ADDRESS_ATTRIBUTES,
      },
      director: {
        dob: '生年月日',
        ...PERSON_NAME_ATTRIBUTES,
      },
      bank_account: {
        account_holder_name: '口座名義',
        bank_name: '金融機関名',
        bank_number: '金融機関番号',
        branch_number: '支店番号',
        account_number: '口座番号',
      },
    },
    payment: {
      payment_date: '支払日',
      total_amount: '合計金額',
    },
    ticket: {
      procedure: '希望する依頼方法',
      repair_request: {
        title: '件名',
        body: '相談内容',
        photo_media_files: '直して欲しいものの写真',
      },
      participants: {
        role: {
          repairer: '受注者',
          caller: '依頼者',
        },
      },
    },
    ticket_comment: {
      body: '本文',
    },
    message: {
      status: {
        all: '全て',
        consultation: '相談',
        finalized: '依頼',
        completed: '完了',
      },
    },
    estimate: {
      title: 'タイトル',
      published_at: '発行日',
      published_date: '発行日',
      expires_date: '有効期限',
      note: '備考',
      add_item: '項目を追加',
      subtotal_amount: '小計',
      consumption_tax_amount: '消費税',
      total_amount: '合計金額',
      application_fee_base: '利用料の対象',
      application_fee: 'ナガク利用料 (20%)',
      revenue: '収益',
      estimate_not_included: '※サービス利用料は見積書には記載されません',
      items: {
        label: '項目',
        amount: '単価',
        ex_tax: '税抜',
        quantity: '個数',
        sum: '計',
      },
      shipping_fee: '送料',
      estimate_delivery_days: '作業期間',
    },
    quotation: {
      title: 'タイトル',
      published_at: '発行日',
      published_date: '発行日',
      expires_date: '有効期限',
      management_number: '見積書ID',
      note: '備考',
      add_item: '項目を追加',
      subtotal_amount: '小計',
      consumption_tax_amount: '消費税',
      total_amount: '合計金額',
      application_fee_base: '利用料の対象',
      application_fee: 'ナガク利用料 (20%)',
      revenue: '収益',
      estimate_not_included: '※サービス利用料は見積書には記載されません',
      items: {
        label: '項目',
        amount: '単価',
        ex_tax: '税抜',
        quantity: '個数',
        sum: '計',
      },
      shipping_fee: '送料',
      estimate_delivery_days: '作業期間',
    },
    order: {
      timestamp: '日時',
      amount: '金額',
    },
    business_profile: {
      description: '概要',
      estimated_delivery: '納品・納期までの目安',
      support_types: '対応する依頼方法',
      shipping_method: '配送方法',
      support_shipping: '配送',
      support_in_store_drop_off: '店舗持込',
      support_on_site_pickup: '出張引取',
      support_on_site_repair: '現地修理',
      accept_repair_request: '新規依頼の受付',

      address_hidden: '住所の公開設定',
      support_areas: '対応地域',
      business_hours: '営業時間',
      regular_closed_days: '定休日',
      notes: '注意事項',
      cancellation_policy: 'キャンセルポリシー',
      reception_message: 'ひとことメッセージ',
      reception_body_annotation: '相談内容の注釈',
      ...ADDRESS_ATTRIBUTES,
    },
    jp_act_on_specified_commercial_transaction: {
      corporate_name: '法人名',
      representative_name: '責任者の名前',
      address: '住所',
      address_hidden: '住所を非表示にする',
      phone: '電話番号',
      phone_hidden: '電話番号を非表示にする',
      business_hours_and_closed_days: '営業時間、定休日など',
      pricing: '主な提供サービスの価格',
      additional_fees: 'その他の費用',
      payment_method: '代金の支払方法',
      payment_timing: '代金の支払時期',
      estimated_delivery: '引き渡し時期',
      cancellation_policy: 'キャンセル・返品について',
      after_service_policy: '納品後の再修理等の対応について',
    },
    inquiry: {
      inquirer_name: 'お名前',
      phone: '電話番号',
      email: 'メールアドレス',
      message_id: 'メッセージID',
      subject: '件名',
      body: '本文',
    },
    bank_account: {
      account_holder_name: '口座名義',
      bank_name: '金融機関名',
      bank_number: '金融機関番号',
      branch_number: '支店番号',
      account_number: '口座番号',
    },
  },

  /**
   * MARK: Annotation
   * Holds additional descriptive notes for attributes.
   */
  annotations: {
    account: {
      screen_name: '一般的な単語だけを使ったユーザーネームは、予告なく利用を停止する可能性があります。',
      bio: '入力した改行は反映されません。',
    },
    business: {
      bank_account: {
        account_number: 'ゆうちょ銀行をご利用の場合、左側に0を加えて7桁にして入力してください。',
        bank_name: '口座名義はこの申請で入力された個人または法人の名前と一致している必要があります。',
      },
    },
    business_profile: {
      address_hidden:
        '非公開に設定した場合でも、市区町村までは公開され、検索に利用されます。また、配送に必要な場合に限り、完全な住所が相手に共有されます。',
    },
    quotation: {
      expires_date: '7日後から180日後まで',
      shipping_fee: '送料を実費で請求する場合に入力してください。送料にはナガクの手数料は適用されません。',
    },
    reception: {
      cancellation_policy: 'キャンセルポリシーは {{breadcrumb}} で編集することができます。',
    },
    ticket: {
      repair_request: {
        photo_media_files:
          '画像は8枚までアップロード可能です。リペアしたい箇所のアップ、全体が写ったものなどを撮影してください。',
      },
      about_consultation_and_requests: '相談後、確定見積もりを受け取り、支払いを完了するまでは、費用はかかりません。',
    },
    jp_act_on_specified_commercial_transaction: {
      address_hidden:
        '住所を非公開にすると、代わりに当社のものが表示されます。依頼者からお問い合わせがあった場合は、必要に応じて開示していただく場合があります。',
      phone_hidden:
        '電話番号を非公開にすると、代わりに当社のものが表示されます。依頼者からお問い合わせがあった場合は、必要に応じて開示していただく場合があります。',
      after_service_policy: '',
    },
    work: {
      tags: 'タグは1個以上付ける必要があります。任意のタグを5個まで付けられます。',
    },
  },

  /**
   * MARK: Validation Guides
   * {please write a simple comment}
   */
  validation_guides: {
    account: {
      display_name: '32文字以内',
      password: '半角英数字および記号を含む8桁以上',
      new_password: '半角英数字および記号を含む8桁以上',
      screen_name:
        '3文字から32文字まで。半角小文字英数字および「-（ハイフン）」「_（アンダースコア）」「+（プラス）」「.（ドット）」 が使用できます。',
      tagline: '32文字以内',
    },
  },

  /**
   * MARK: Placeholder
   */

  placeholder: {
    email: 'nagaku@example.com',
    password: 'パスワードを入力してください',
    phone: '000-0000-0000',
    tags: 'リペア・リメイクのプロや事例を検索',
    search_by_tag: 'タグで検索',
    account: {
      display_name: '他のユーザーに表示する名前',
      screen_name: 'your-account-id',
      email: 'nagaku@example.com',
      password: 'パスワードを入力してください',
      current_password: '入力してください',
      confirmation_password: 'もう一度入力してください',
      tagline: '革製品修理とスニーカーリメイク',
      bio: '入力してください',
    },
    address: {
      name: '長久太郎',
      name_kana: 'ナガクタロウ',
      phone: '000-0000-0000',
      postal_code: '000-0000',
      state: '都道府県',
      city: '市区郡',
      town: '大字/丁目',
      line1: '地番 0-00-00',
      line2: '建物詳細',
    },
    ticket: {
      repair_request: {
        body: 'どのように直して欲しいのか、希望する予算や納期など',
      },
    },
    address_kana: {
      postal_code: '000-0000',
      state: '都道府県(カナ)',
      city: '市区郡',
      town: '大字/丁目',
      line1: '地番 0-00-00',
      line2: '建物詳細',
    },
    business: {
      description:
        '東京都渋谷区に工房を構え、ビンテージ家具や内装の修理、塗装の塗り直し、リサイズやカスタムなどを行っています。納期は約2カ月です。',
      jp_trn_number: 'T0000000000000',
      company: {
        name_kanji: 'ナガク株式会社',
        name_kana: 'ナガク（カ',
      },
      bank_account: {
        account_holder_name: 'ナガクタロウ',
        bank_name: 'ひらがな・カタカナ・ローマ字で銀行を検索',
        bank_number: '0000',
        branch_number: '000',
        account_number: '1234567',
      },
    },
    business_profile: {
      description: '入力してください',
      estimated_delivery: '一般的な家具リペアの場合、3カ月ほどいただいております。',
      shipping_method: 'ヤマト運輸らくらく家財宅急便',
      support_areas: '東京都23区、神奈川県、千葉県の一部',
      business_hours: '9:00〜18:00',
      regular_closed_days: '土日祝日',
      notes: '入力してください',
      cancellation_policy: '入力してください',
      reception_message_legend: 'ここに入力した内容が表示されます',
      reception_body_annotation_legend: 'ここに入力した内容が表示されます',
      reception_message: '入力してください',
      reception_body_annotation: '入力してください',
    },
    estimate: {
      title: '椅子のリペアのお見積り - {{display_name}}様',
      items: {
        label: 'リペア作業代',
        amount: '24,000',
      },
      shipping_fee: '1,000',
      estimate_delivery_days: '1',
    },
    quotation: {
      title: '椅子のリペアのお見積り - {{display_name}}様',
      items: {
        label: 'リペア作業代',
        amount: '24,000',
      },
      shipping_fee: '1,000',
      estimate_delivery_days: '1',
    },
    jp_act_on_specified_commercial_transaction: {
      corporate_name: 'ナガク株式会社',
      representative_name: '長久 太郎',
      address: '長野県長野市大字南長野字幅下692-2',
      phone: '000-0000-0000',
      business_hours_and_closed_days: '入力してください',
      pricing: '入力してください',
      additional_fees: '入力してください',
      estimated_delivery: '入力してください',
      cancellation_policy: '入力してください',
      after_service_policy: '入力してください',
    },
    ticket_comment: {
      body: 'メッセージを入力',
    },
    work: {
      body: '工夫した点やこだわりポイント、制作ストーリーなど',
      estimate_amount: '15,000',
      estimate_delivery_days: '4',
    },
  },

  /**
   * MARK: action
   * the labels for buttons and other interaction elements.
   */
  'action.back': '戻る',
  'action.login': 'ログイン',
  'action.signup': '新規登録',
  'action.signup_or_login': 'ログイン・新規登録',
  'action.continue_with_google': 'Google で続ける',
  'action.logout': 'ログアウトする',
  'action.create_account': '登録する',
  'action.search_by_tag': 'タグから探す',
  'action.see_more_tag': 'タグをもっと見る',
  'action.post': '事例を投稿する',
  'action.send': '送信する',
  'action.verify': '確認する',
  'action.verify_email': 'メールアドレスを確認する',
  'action.edit': '編集する',
  'action.edit_input': '入力内容を編集する',
  'action.modify_input': '入力内容を修正する',
  'action.copy_work_url': '投稿のURLをコピー',
  'action.review_input': '入力内容を確認する',
  'action.next': '次へ',
  'action.check_account_setting': '確認する',
  'action.save_as_draft': '下書き保存する',
  'action.close': '閉じる',
  'action.save': '保存する',
  'action.close_without_saving': '保存せず閉じる',
  'action.delete': '削除する',
  'action.update': '更新する',
  'action.attach': '添付する',
  'action.attach_file': '画像',
  'action.attach_estimates': '見積もり',
  'action.attach_quotation': '確定見積もり（請求）',
  'action.attach_estimate': '概算見積もり',
  'action.complete_edit': '編集を完了する',
  'action.like': 'いいねする',
  'action.unlike': 'いいねを取り消す',
  'action.follow': 'フォローする',
  'action.unfollow': 'フォロー解除',
  'action.reupload': '再アップロードする',
  'action.show_latests': '最新情報を表示',
  'action.show_more': 'さらに表示',
  'action.view_all': '続きを見る',
  'action.visit_profile': 'プロフィールを見る',
  'action.edit_profile': 'プロフィールを編集',
  'action.contact': '相談する',
  'action.resend_verification_email': 'メールを再送する',
  'action.register_address': '新しい住所を追加する',
  'action.make_primary_address': '主な住所にする',
  'action.reset_password': 'パスワードを再設定する',
  'action.submit_application': '申請を送信する',
  'action.withdraw_application': '申請を取り下げる',
  'action.open_pro_account': 'プロアカウントを開設する',
  'action.start_business_account_application': 'プロアカウント申請を始める',
  'action.cancel_business_account_application': 'プロアカウント申請を取り消し',
  'action.spam_report': '報告する',
  'action.order_and_pay': '依頼を確定して支払う',
  'action.complete_order': '納品して依頼を完了する',
  'action.accept_order_completion': '依頼の完了を承認する',
  'action.review_and_pay': '依頼の確認・決済に進む',
  'action.quotation_delete': '入力内容を削除する',
  'action.estimate_delete': '入力内容を削除する',
  'action.show_details': '詳細を見る',
  'action.print_quotation': '見積書兼請求書を表示する',
  'action.goto_the_message_for_order': 'この依頼・受注のメッセージへ',
  'action.show_quotaiton_details': '内容を確認する',
  'action.show_estimate_details': '内容を確認する',
  'action.review_and_pay_quotation': '内容を確認して支払う',
  'action.edit_business_profile': 'ビジネス情報を編集する',
  'action.enter_jp_act_on_specified_commercial_transaction': '特定商取引法に基づく表記を入力する',
  'action.edit_jp_act_on_specified_commercial_transaction': '特定商取引法に基づく表記を編集する',
  // for Pro Account Consultation
  'action.ask': '依頼の相談をする',
  'action.check_my_ask_page': '自分の相談ページを確認',
  'action.pause_accepting': '新規受付停止中',
  'action.submit_repair_request': '相談を送る',
  'action.open_message_inspector': 'メッセージの詳細情報を開く',
  'action.previous_image': '前の画像',
  'action.next_image': '次の画像',

  /**
   * MARK: Enum
   * Naming rule: enum.{model}.{attribute}.{value}
   */
  enum: {
    delivery_days_unit: {
      day: '日間',
      week: '週間',
      month: 'カ月',
    },
    procedure: {
      shipping: '配送',
      in_store_drop_off: '店舗持込',
      on_site_pickup: '出張引取',
      on_site_repair: '現地修理',
      undefined: '希望なし',
    },
    'business.status': {
      // reference Business['status']
      draft: '申請前', // This translation phrase is unintuitive, but we accepts.
      in_review: '審査中',
      verified: '承認済み',
      open: '開設済み',
    },
    'jp_act_on_specified_commercial_transaction.address_hidden': {
      no: '住所を公開する',
      yes: '住所を非公開にする',
    },
    'jp_act_on_specified_commercial_transaction.phone_hidden': {
      no: '電話番号を公開する',
      yes: '電話番号を非公開にする',
    },
  },

  /**
   * MARK: unit
   */
  unit: {
    separator: '',
    works: '投稿',
    delivery_days: {
      day: '日間',
      week: '週間',
      month: 'カ月',
    },
    like_count: 'いいね！',
  },

  /**
   * MARK: link @deprecated
   */
  'link.contact': 'お問い合わせ',
  'link.back_to_home': 'ホームに戻る',
  'link.about_us': '運営会社',
  'link.privacy_policy': 'プライバシー​ポリシー',
  'link.terms_of_service': '利用規約',
  'link.careers': '採用情報を見る',
  'link.japanese_act_on_specified_transactions': '特定商取引法に基づく表記',

  /**
   * MARK: guide @deprecated
   */
  'guide.no_works_yet': 'まだ投稿はありません',
  'guide.page_not_found': 'ページが見つかりません',
  'guide.hello': '{{name}}さん、こんにちは！',
  'guide.post_a_work': 'リペアやリメイクの記録を投稿してみましょう',
  'guide.work_estimate_amount': 'このリペア、リメイクを依頼した場合の料金',
  'guide.contact': 'お問い合わせは現在メールでのみ受け付けています。',
  'guide.this_page_is_for_seinding_inquiries_to_your_account':
    'このページはあなたのアカウントへのお問い合わせページです。URLをコピーして誰かに共有しても構いません。',
  'guide.have_an_account_already': 'アカウントをお持ちの方',
  'guide.forget_password': 'パスワードを忘れた方はこちら',
  'guide.has_been_uploaded': 'アップロード済み',
  'guide.has_uploading_error': 'アップロード時にエラー',
  'guide.please_verify_email': '確認メールに記載されたURLにアクセスして、メールアドレスの確認を完了してください。',
  'guide.please_verify_email_to_send_repair_request':
    '依頼の相談をするには、メールアドレスの確認が完了している必要があります。',
  'guide.reset_password': '新しいパスワードを入力して、パスワードの再設定を完了してください。',
  'guide.recover_password':
    'あなたのアカウントのメールアドレスを入力してください。入力されたメールアドレス宛にパスワードの再設定メールを送信します。',
  'guide.recover_password_email': '入力されたメールアドレスが正しい場合、パスワードの再設定メールが送信されます。',
  'guide.support_png_or_jpeg': 'PNG または JPEG のみ対応',
  'guide.verify_new_email':
    '更新すると新しいメールアドレスに認証メールを送信します。記載のURLにアクセスして認証を完了してください。',
  'guide.jp_trn_number_is_not_provided':
    'インボイス登録番号（適格請求書発行事業者登録番号）は未入力です。この項目はオプションです。',
  'guide.business': {
    description: '提供するサービスの説明を 2、3文程度で記入してください。',
    company_representative: '代表者として会社の取締役1名の情報をご本人が入力してください。',
    need_to_verify_email_to_open: 'プロアカウントを開設するには、メールアドレスの確認が必要です。',
    need_to_verify_email_to_submit: 'プロアカウント申請を送信するには、メールアドレスの確認が必要です。',
    verified: '申請が承認されました。プロフィールやビジネス情報を入力して、プロアカウントをオープンしましょう。',
    open: 'プロアカウントの開設は準備中です。正式リリースをお待ち下さい。',
  },
  'guide.business_draft': 'フォームを入力して、申請を送ってください。',
  'guide.business_privacy_policy':
    '入力された情報は、審査のため Stripe（決済サービス）に提供されます。詳細についてはプライバシーポリシーをご覧ください。',
  'guide.business_is_in_review':
    '申請いただいた情報は現在審査中です。審査は通常5営業日以内に完了します。情報に不足や不備があった場合は、メールでお知らせします。',
  'guide.privacy_policy_translation': '',
  'guide.terms_of_service_translation': '',
  'guide.only_ja': '',
  'guide.unauthorized': '認証情報が正しくなかったため、アクセスしようとしたページを表示できませんでした。',
  'guide.print_annotation': 'このページは印刷用のページです。印刷してご利用ください。',
  'email_verification.instruction': '以下のボタンを押して、メールアドレスの確認を完了してください。',
  'email_verification.status.completed': {
    title: 'メールアドレスの検証に成功しました！',
    message: 'ご協力ありがとうございます。メールアドレスの検証に成功しました。',
    action: 'トップページに戻る',
  },
  'email_verification.status.expired': {
    title: 'メールアドレスの検証に失敗しました',
    message: 'URLが期限切れか、すでに処理済みの可能性があります。メールアドレス検証用のURLは24時間だけ有効です。',
  },
  'email_verification.status.error': {
    title: 'メールアドレスの検証に失敗しました',
    message: '不正なURLか、サービスに不具合が起こっている可能性があります。',
  },

  'guide.navigate_customer_support':
    'また、このエラーが頻繁に表示される場合、サービスが不安定になっている可能性があります。しばらく時間をおいてから再度ご利用いただくか、サポートまでお問い合わせください。',
  'guide.payment': {
    complete: '決済が完了し、依頼が確定しました。',
  },
  'guide.pro_account_profile': 'カバー写真、タグラインはプロアカウントをオープンすると表示されます。',
  'guide.edit_business_profile_page': 'こちらに入力した情報は、プロフィールの「依頼について」のタブに表示されます。',
  'guide.quotation': {
    already_paid: 'すでに支払いが完了しています。',
    already_expired: '有効期限が切れています。',
  },
  'guide.jp_act_on_specified_commercial_transaction_for_pro_account':
    'インターネットを通じリペアサービス等を提供する場合、特定商取引法に基づく表記の掲載が義務付けられています。',
  'guide.japanese_act_on_specified_transactions_translation': '',
  'guide.inquiry': {
    domain_filter:
      '折返しのメールが受信できるように、ドメイン指定受信で「nagaku.com」と「{{domain}}」を許可するように設定してください。',
    complete:
      '{{display_name}} へお問い合わせを送信いたしました。返答はご入力頂いたメールアドレスに直接送らせていただきます。',
  },
  'guide.about_business_director': '事業担当者と会社の代表者が異なる',

  /**
   * MARK: message @deprecated
   */
  'message.success': '成功しました',
  'message.spam_report_success': '報告を受け付けました',
  'message.successfully_logged_in': 'ログインに成功しました',
  'message.successfully_open_pro_account': 'プロアカウントを開設しました。',

  /**
   * MARK: confirmation @deprecated
   */
  confirmation: {
    'work.delete': {
      title: '削除の確認',
      description: '削除した投稿は復旧できません、削除してよろしいですか？',
      cancel: 'キャンセル',
      confirm: '削除する',
    },
    'profile.discard': {
      title: '編集された内容があります',
      description: '',
      cancel: '編集を続ける',
      confirm: '破棄して戻る',
    },
    'business.cancel_application': {
      title: '申請の取り消し',
      description: '取り消しされたプロアカウントの申請は復元できません。本当に取り消してよろしいですか？',
      cancel: 'キャンセルして戻る',
      confirm: '申請を取り消し',
    },
    'business.submit_application': {
      title: 'プロアカウント申請の送信',
      description: '申請を送信すると、審査中は内容の変更ができません。送信前にすべての情報が正しいか確認してください。',
      cancel: '入力に戻る',
      confirm: '申請を送信',
    },
    'account_setting.discard': {
      title: '入力内容を破棄しますか？',
      description: '破棄するを選択すると、入力内容が消えてしまいます。このまま閉じてよろしいですか？',
      cancel: '編集を続ける',
      confirm: '破棄する',
    },
  },

  // MARK: Misc, requires refactoring.

  inquiry_subject: 'お問い合わせ ナガク',

  'valication_rule.dob': '無効な日付です',

  'validation.required': '{{name}}は必須です。',
  'validation.max_length': '{{name}}は{{length}}文字以下にしてください。',
  'validation.min_length': '{{name}}は{{length}}文字以上にしてください。',
  'validation.array_max_length': '{{name}}は{{length}}件以下にしてください。',
  'validation.include_numeric_character': '{{name}}には数値を含めてください。',
  'validation.include_symbolic_character': '{{name}}には記号を含めてください。',
  'validation.include_alphabetic_character': '{{name}}には英字を含めてください。',
  'validation.meets_password_format': '{{name}}には使用できない文字が含まれています。',
  'validation.invalid_password': '{{name}}は不正なパスワードです。',
  'validation.confirmation_mismatch': '{{name}}が一致しません。',
  'validation.pattern': '{{name}}は不正です。',
  'validation.invalidDate': '{{name}}は不正な日付です。',
  'validation.18_or_more_age': '18歳以上のみご利用いただけます。',
  'validation.invalid_postalcode': '不正な郵便番号です',
  'validation.kana': '{{name}}はひらがなまたはカタカナのみ利用可能です',
  'validation.address_line_kana': '{{name}}はひらがな、カタカナ、数字、アルファベットと一部の記号のみ利用可能です',
  'validation.unknown_postalcode': '不明な郵便番号です',
  'validation.unknown_bank_name': '不明または未登録の金融機関名です。お手数ですが、他の金融機関を試してください',
  'validation.range': '{{name}}は{{min}}~{{max}}の値にしてください',
  'validation.amount': '{{name}}は半角数字および,(カンマ)のみ利用可能です',
  'validation.delivery_days': '{{name}}は半角数字およびのみ利用可能です',
  'validation.positive_number': '{{name}}は0より大きい数のみ入力できます。',
  'validation.only_integer': '{{name}}には整数のみ入力できます。',
  'validation.mime_type': '対応していないファイル形式です。利用可能なファイル形式は {{name}} です。',

  control: {
    required: '必須',
    optional: '任意',
    auto_filled: '自動入力',
  },

  status: {
    payment: {
      successful: '支払い完了',
      confirmed: '確定した依頼 - {{payer}}様',
    },
    ticket: {
      complete: '依頼が完了しました',
      accept: '修理を確認しました',
    },
    work: {
      draft: '下書き',
      published: '公開済み',
    },
    account: {
      following: 'フォロー中',
    },
  },

  'form.business': {
    overview: 'ビジネスの概要',
    company_overview: '法人の情報',
    company_address: 'ビジネスの住所',
    representative: '責任者の個人情報',
    representative_address: '責任者の住所',
    id_documents: '身分証明書類',
    contact: '責任者の連絡先',
    bank_account: '銀行口座',
  },

  'form.business_profile': {
    address: '住所',
    hide_details: '詳細を非公開にする',
    show_details: '公開する',
    accepting: '受付中',
    paused: '一時停止',
  },

  'form.date': {
    dob: '生年月日',
    year: '年',
    month: '月',
    day: '日',
    dob_and_age: '{{year}}年{{month}}月{{day}}日生まれ - {{age}}歳',
  },

  empty: {
    general: {
      title: '表示するコンテンツが見つかりません',
      guide: 'このタグが付いたコンテンツが見つかりませんでした。他のタグを選択してみましょう。',
    },
    tag: {
      title: '表示するコンテンツが見つかりません',
      guide: 'このタグが付いたコンテンツが見つかりませんでした。他のタグを選択してみましょう。',
    },
    order: {
      title: '依頼履歴がありません',
      guide: 'あなたが依頼したリペア・リメイクの履歴が表示されます。',
    },
    dashboard_order: {
      title: '受注がありません',
      guide: 'あなたが受注したリペア・リメイクが表示されます。',
    },
    message: {
      title: '表示するメッセージがありません',
      guide: 'あなたに届いたメッセージが表示されます。',
    },
    like: {
      title: 'いいねしたコンテンツがありません',
      guide: 'お気に入りのコンテンツを見つけて、いいねを押してみましょう。',
    },
  },

  values: {
    business: {
      business_type: {
        individual: '個人',
        company: '法人',
      },
    },
  },

  // MARK: old type phrases

  BeforeAfterSlider: {
    BlankImage: '空白画像',
    BeforeImage: 'ビフォア画像',
    AfterImage: 'アフター画像',
    Handle: 'ビジュアルスライダー',
  },

  PermissionMissingView: {
    title: '権限がありません',
    message: 'このページを閲覧する権限がありません。（パーミッションエラー）',
  },

  OrderMessageView: {
    message: {
      for_non_caller:
        '{{caller_display_name}}さんの支払いが完了し、依頼が確定しました。作業に着手してください。作業が完了し、発送したら、「納品して依頼を完了する」ボタンを押してください。',
      for_caller: '支払いが完了し、依頼が確定しました。作業完了をお待ちください。',
    },
  },

  StartOrderCompletionMessageView: {
    message: {
      for_non_caller: '{{caller_display_name}}さんに依頼完了のお知らせを送信しました。',
      for_caller:
        '{{repairer_display_name}}さんから依頼完了のお知らせが届きました。品物を確認し、作業内容に問題がなければ依頼の完了を承認してください。',
    },
    guide: '何もしなければ、依頼は{{expires_at}}に自動的に完了します。',
    accept: {
      title: '依頼の完了',
      body: '{{repairer_display_name}}さんから品物を受け取り、状態が確認できたら「依頼の完了を承認する」ボタンを押してください。',
    },
  },

  AcceptOrderCompletionMessageView: {
    message: {
      for_non_caller: '{{caller_display_name}}さんが依頼を完了しました。',
      for_caller: '依頼を完了しました。',
    },
  },

  WorkCard: {
    ImageCountLabel: 'この投稿には他に{{count}}枚の写真があります',
  },

  'Message is already closed': 'やり取りは終了しています',

  /**
   * MARK: for each Actions.
   *
   * Actions are defined in the `./src/actions` directory.
   * Each action displays the result (success or error) via the <Toast> component.
   * These phrases are used as <Toast> messages.
   *
   * @reference ./src/lib/action.ts
   */

  'Sample Message': 'サンプルメッセージ',

  onPublishWork: {
    success: '投稿が完了しました',
    error: '投稿に失敗しました。 {{message}}',
  },

  onSaveDraftWork: {
    success: '下書きを保存しました',
    error: '下書きの保存に失敗しました。 {{message}}',
  },

  onOpenTicket: {
    success: 'メッセージの送信が完了しました',
    error: '相談の送信に失敗しました。{{message}}',
  },

  onCompleteOrder: {
    success: '依頼の完了を通知しました',
    error: '依頼の完了に失敗しました {{message}}',
  },

  onAcceptOrderCompletion: {
    success: '依頼の完了を受け入れました',
    error: '依頼の完了に失敗しました。 {{message}}',
  },

  onRegisterAccount: {
    success: 'アカウントを登録しました。',
    error: 'アカウント登録に失敗しました。{{message}}',
  },

  onOpenBusinessAccount: {
    success: 'プロアカウントを開設しました',
    error: 'プロアカウントの開設に失敗しました。{{message}}',
  },

  onUpdateProfile: {
    success: 'プロフィールを更新しました',
    error: 'プロフィールの更新に失敗しました。{{message}}',
  },

  onUploadBanner: {
    success: 'バナー画像をアップロードしました。更新すると変更が反映されます。',
    error: 'バナー画像のアップロードに失敗しました。{{message}}',
  },

  onUploadIcon: {
    success: 'アイコン画像をアップロードしました。更新すると変更が反映されます。',
    error: 'アイコン画像のアップロードに失敗しました。{{message}}',
  },

  onBecomePrimaryAddress: {
    success: '「主な住所」を変更しました',
    error: '「主な住所」の変更に失敗しました。{{message}}',
  },

  onSubmitBusinessApplication: {
    success: 'プロアカウント申請を送信しました。',
    error: '申請の送信に失敗しました。{{message}}',
  },

  onWithdrawBusinessApplication: {
    success: 'プロアカウント申請を取り下げました。',
    error: 'プロアカウント申請の取り下げに失敗しました。 {{message}}',
  },

  onUpdateJpActOnSpecifiedCommercialTransaction: {
    success: '特定商取引法に基づく表記を更新しました。',
    error: '特定商取引法に基づく表記の更新に失敗しました。 {{message}}',
  },

  onUpdateEmail: {
    success: '新しいメールアドレスに確認メールを送信しました。メールアドレスが確認されれば、変更が完了します。',
    error: 'メールアドレスの更新に失敗しました。{{message}}',
  },

  onUpdatePassword: {
    success: 'パスワードを更新しました',
    error: 'パスワードの更新に失敗しました。{{message}}',
  },

  onKickRecoveryPassword: {
    success: 'パスワード復旧メールを送信しました。入力したメールアドレスが正しければ、パスワード復旧メールが届きます。',
    error: 'パスワード復旧メールの送信に失敗しました。{{message}}',
  },

  onRegisterAddress: {
    success: '住所を登録しました。',
    error: '住所の登録に失敗しました。{{message}}',
  },

  onDeleteAddress: {
    success: '住所を削除しました。',
    error: '住所の削除に失敗しました。{{message}}',
  },

  onUpdateBusinessProfile: {
    success: 'ビジネス情報を更新しました。',
    error: 'ビジネス情報の更新に失敗しました。{{message}}',
  },

  onUpdateReception: {
    success: '相談ページを更新しました。',
    error: '相談ページの更新に失敗しました。{{message}}',
  },

  onUpdateBusinessBankAccount: {
    success: '保存しました。',
    error: '保存に失敗しました。{{message}}',
  },

  onUpdateBusinessCompanyAddress: {
    success: '保存しました',
    error: '保存に失敗しました。{{message}}',
  },

  onUpdateBusinessCompany: {
    success: '保存しました',
    error: '保存に失敗しました。{{message}}',
  },

  onUpdateBusinessOverview: {
    success: '保存しました',
    error: '保存に失敗しました。{{message}}',
  },

  onUpdateBusinessRepresentative: {
    success: '責任者の個人情報を保存しました',
    error: '保存に失敗しました。{{message}}',
  },

  onUpdateBusinessRepresentativeAddress: {
    success: '保存しました',
    error: '保存に失敗しました。{{message}}',
  },

  onUpdateBusinessRepresentativeContact: {
    success: '保存しました',
    error: '保存に失敗しました。{{message}}',
  },

  onUpdateQuotation: {
    success: '見積もりを更新しました',
    error: '見積もりの更新に失敗しました。{{message}}',
  },

  onUpdateEstimate: {
    success: '概算見積もりを更新しました',
    error: '概算見積もりの更新に失敗しました。{{message}}',
  },

  onPayQuotation: {
    success: '支払いを開始します（Stripe にリダイレクトします）',
    error: '支払いプロセスの開始に失敗しました。{{message}}',
  },

  onUpdateWork: {
    success: '投稿を更新しました',
    error: '投稿の更新に失敗しました。{{message}}',
  },

  onLogin: {
    success: 'ログインしました',
    error: 'ログインに失敗しました。{{message}}',
  },

  onUploadImage: {
    success: '画像をアップロードしました',
    error: '画像のアップロードに失敗しました。{{message}}',
  },

  onComment: {
    success: 'コメントしました',
    error: 'コメントに失敗しました。{{message}}',
  },

  onCopyWorkUrl: {
    success: 'URLをコピーしました',
    error: 'URLのコピーに失敗しました。{{message}}',
  },

  onPostInquiry: {
    success: 'お問い合わせを送信しました',
    error: 'お問い合わせの送信に失敗しました。{{message}}',
  },
};

export default { phrases } as PolyglotOptions;
